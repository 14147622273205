var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bill-payment-detail-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "支付明细",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_c('div', {
    staticClass: "bill-payment-detail-content"
  }, [_c('van-list', {
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getOrderPayments
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.payments, function (payment, index) {
    return _c('van-cell', {
      key: index,
      staticClass: "bill-payment-detail-cell",
      attrs: {
        "title": payment.client.name,
        "value": '￥' + payment.real_amount,
        "is-link": "",
        "center": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toClientPaymentView(payment);
        }
      }
    });
  }), 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }