<template>
  <div class="bill-payment-detail-container">
    <my-nav-bar
      title="支付明细"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <div class="bill-payment-detail-content">
        <van-list
          v-model="loading"
          :finished="finished"
          @load="getOrderPayments"
        >
          <van-cell
            v-for="(payment, index) in payments"
            :key="index"
            class="bill-payment-detail-cell"
            :title="payment.client.name"
            :value="'￥' + payment.real_amount"
            is-link
            center
            @click="toClientPaymentView(payment)"
          />
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import billApi from '@/api/bill'
import myNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'BillPaymentDetail',
  components: { myNavBar },
  data() {
    return {
      payments: [],
      page: 0,
      limit: 10,
      loading: false,
      finished: false
    }
  },
  methods: {
    getOrderPayments() {
      this.page++
      const params = {
        bill_id: this.$route.query.bill_id,
        page: this.page,
        limit: this.limit
      }
      billApi.paymentDetail(params).then(res => {
        this.payments = this.payments.concat(res.data.list)
        this.loading = false
        if (res.data.list.length < this.limit) { this.finished = true }
      })
    },
    toClientPaymentView(payment) {
      this.$router.push({
        path: '/bill-client-payment',
        query: {
          payment_id: payment.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .bill-payment-detail-cell {
    height: 50px;
    .van-cell {
      &__title {
        flex: 3;
      }
      &__value {
        color: #ee0a24;
      }
    }
  }
</style>
